@use './variables.sass' as v

@font-face
  font-family: Manrope
  src: url('./assets/fonts/Manrope-Medium.woff2')
  src: url('./assets/fonts/Manrope-Medium.woff')
  font-display: swap
  font-weight: 500
  font-style: normal

@font-face
  font-family: Manrope
  src: url('./assets/fonts/Manrope-SemiBold.woff2')
  src: url('./assets/fonts/Manrope-SemiBold.woff')
  font-display: swap
  font-weight: 600
  font-style: normal

@font-face
  font-family: Manrope
  src: url('./assets/fonts/Manrope-Bold.woff2')
  src: url('./assets/fonts/Manrope-Bold.woff')
  font-display: swap
  font-weight: 700
  font-style: normal

*, *::before, *::after
  box-sizing: border-box

*
  margin: 0
  padding: 0

html, body
  width: 100%
  // height: 100vh
  overflow-x: hidden

  body
    font-family: Manrope, Helvetica, sans-serif
    color: v.$color-med
    background-color: #202020

  #root
    display: flex
    flex-direction: column
    width: 100%
    // min-height: 100%

$gap: 50px
$margin: 30px
$color-dark: #323232
$color-med: #959595
$color-light: #dedede
$color-btn: #b4b3b3
$color-error: #f35252
$color-bright: #e5ff60
$color-error: #cc2c2c
$color-success: #174616

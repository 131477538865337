@use '../../../variables.sass' as v

.sidebar
  position: fixed
  top: 120px
  left: 50px
  max-width: 200px
  z-index: 2
  &__icon
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    width: 25px
    .dot
      height: 10px
      width: 10px
      background-color: v.$color-light
      border-radius: 50%
      display: inline-block
      &:not(:last-of-type)
        margin-bottom: 5px
      &:last-of-type
        margin-bottom: 15px

  @media screen and (max-width: 1500px)
    left: 2vw
    &__title
      margin-bottom: 15px
  .sidebar-nav
    &__item
      font-size: 22px
      font-weight: 600
      color: v.$color-light
      border-bottom: 1px solid transparentize(v.$color-med, .5)
      transition: background-color .3s ease-in-out
      &:has(.sidebar-nav__link:hover)
        background-color: transparentize(v.$color-bright, 0.8)
    &__link
      display: block
      width: 100%
      padding: 5px 0
  @media screen and (max-width: 1000px)
    &__title
      font-size: 22px
    .sidebar-nav__item
      font-size: 19px
      border-bottom: none
    .sidebar-nav__link
      padding: 7px 0
  @media screen and (max-width: 800px)
    .sidebar__wrap
      opacity: 0
      transform: translateX(-200px)
      transition: all .2s ease-out
      &.is-active
        padding: 10px
        background-color: transparentize(v.$color-dark, 0.1)
        z-index: 2
        transform: translateX(0px)
        opacity: 1

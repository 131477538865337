@use '../../variables.sass' as v
.brief
  position: relative
  width: 90%
  &__text
    margin-bottom: 15px
    font-size: 34px
    font-weight: 600
    text-align: center
    @media screen and (max-width: 1500px)
      font-size: 30px
    @media screen and (max-width: 1000px)
      font-size: 20px
  &__img
    position: absolute
    right: 15%
    bottom: 20px
    width: 260px
    height: 800px
    background-image: url('../../assets/images/panda2.png')
    background-repeat: no-repeat
    background-size: contain
    z-index: -1
    @media screen and (max-width: 1500px)
      right: 5%
      height: 70%
    @media screen and (max-width: 800px)
      display: none
.brief-form
  display: flex
  flex-direction: column
  width: 35vw
  margin: 2rem auto
  padding: 3rem
  @media screen and (max-width: 1500px)
    width: 45vw
  @media screen and (max-width: 1000px)
    width: 60vw
  @media screen and (max-width: 800px)
    width: 70vw
    padding: 0.51rem
  &__input
    position: relative
    min-height: 40px
    padding: 0.25rem
    color: v.$color-light
    font-size: 20px
    border: none
    background-color: transparent
    border: none
    border-bottom: 3px solid v.$color-light
    transition: all 0.2s ease
    &:focus-visible, &:focus
      outline: none
      border-color: #fff
    &:focus-visible + .brief-form__label, &:focus + .brief-form__label
      color: #fff
      transform: translate(0, -5rem)
    &:not(:placeholder-shown) + .brief-form__label
      transform: translate(0, -5rem)
  &__textarea
    min-height: 20vh
    padding-top: 1.25rem
    &:focus-visible + .brief-form__label, &:focus + .brief-form__label
      color: #fff
      transform: translate(0, -13.5rem)
    &:not(:placeholder-shown) + .brief-form__label
      transform: translate(0, -13.5rem)
  &__label
    display: block
    color: #999
    transform: translate(0.25rem, -1.75rem)
    transition: all 0.2s ease-out
  .brief-form__btn
    position: relative
    align-self: flex-start
    padding: 10px 40px
    font-size: 1.5rem
    font-weight: 700
    text-transform: uppercase
    background-color: v.$color-btn
    &:hover
      box-shadow: inset 0 -3.25em 0 0 v.$color-bright
    @media screen and (max-width: 800px)
      align-self: center

  @keyframes ripple
    0%
      opacity: 1
      transform: scale(0)
    100%
      opacity: 0
      transform: scale(10)
